import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';
import { canUse, FeatureFlag } from '../common';

export const routes: RouteRecordRaw[] = [
  {
    path: '/assets-new/:id',
    name: RouteName.DETAILS,
    component: () => import('./views/DetailsView'),
    props: true,
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/assets-new/:id/edit-new',
    name: RouteName.EDIT,
    component: () => import('./views/EditView'),
    props: true,
    meta: { atlaskit: true, contained: true },
  },
  {
    path: '/assets-new/:id/copy',
    name: RouteName.COPY,
    component: () => import('./views/CopyView'),
    props: true,
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
];

if (canUse(FeatureFlag.ASSET_HOME_NEW)) {
  routes.push({
    path: '/assets',
    name: RouteName.HOME,
    component: () => import('./views/AssetsHomeView'),
    meta: { atlaskit: true, appWrapper: true },
  });
}
