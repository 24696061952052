import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/settings/kandji',
    name: RouteName.KANDJI,
    component: () => import('./views/KandjiView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/settings/jamf',
    name: RouteName.JAMF,
    component: () => import('./views/JamfView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/settings',
    component: () => import('./layouts/TabbedLayout.vue'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    children: [
      {
        path: '',
        name: RouteName.HOME,
        component: () => import('./views/HomeView'),
      },
      {
        path: 'qr-code',
        name: RouteName.QR_CODE,
        component: () => import('./views/QRCodeView'),
      },
      {
        path: 'field-sync',
        name: RouteName.FIELD_SYNC,
        component: () => import('./views/FieldSyncView'),
      },
      {
        path: 'api-and-events',
        name: RouteName.API_AND_EVENTS,
        component: () => import('./views/APIAndEventsView'),
      },
      {
        path: 'integrations',
        name: RouteName.INTEGRATIONS,
        component: () => import('./views/IntegrationsView'),
      },
      {
        path: 'customer-portal',
        name: RouteName.PORTAL,
        component: () => import('./views/PortalView'),
      },
    ],
  },
];
