import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES } from './routes';
import atlaskitStyles from '@atlaskit/css-reset?raw';
import { ATLASSIAN_PARAMS } from '@/modules/auth/constants';
import { loadFeatureFlags } from '@/modules/common';

const router = createRouter({
  history: createWebHistory('/'),
  routes: [...ROUTES],
});

if (window.AP) {
  // We use getLocation to find out if the URL was modified
  window.AP.getLocation((hostLocation) => {
    const currentPath = hostLocation.split('#!');
    if (currentPath.length <= 1) return;

    const query = new URLSearchParams(window.location.search);
    const appPath = currentPath[1];
    const appPathQuery = appPath.split('?');

    if (appPathQuery.length > 1) {
      const pathQuery = new URLSearchParams(appPathQuery[1]);
      pathQuery.forEach((value, key) => {
        query.append(key, value);
      });
    }

    const queryObject = Object.fromEntries(query.entries());
    router.push({ path: appPath, query: queryObject });
  });

  // Update Atlassian's URL history to ensure we can go back and forth
  router.beforeEach((to, _, next) => {
    if (!window.AP) return next();

    const appQueryParams = new URLSearchParams();
    const queryParams = new URLSearchParams(to.query as Record<string, string>);

    queryParams.forEach((value, key) => {
      if (ATLASSIAN_PARAMS.includes(key)) return;

      appQueryParams.append(key, value);
    });

    const newPath = appQueryParams.size ? `${to.path}?${appQueryParams}` : to.path;
    window.AP.history.pushState(newPath);
    next();
  });
}

router.beforeEach(async () => {
  await loadFeatureFlags();
});

router.beforeResolve((to) => {
  const ATLASKIT_STYLES_ID = 'atlaskit-styles';
  const hasAtlaskitStyles = document.getElementById(ATLASKIT_STYLES_ID);
  if (to.meta.atlaskit) {
    if (hasAtlaskitStyles) return;

    const style = document.createElement('style');
    style.id = ATLASKIT_STYLES_ID;
    style.innerHTML = atlaskitStyles;
    document.head.appendChild(style);
  } else {
    if (!hasAtlaskitStyles) return;

    hasAtlaskitStyles.remove();
  }
});

// Force reload the page when we try to reload a non-existent chunk. This should
// only happen after an update, if the previous chunk was cached by the users
// browser.
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.assign(to.fullPath);
  }
});

export default router;
