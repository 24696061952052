export const ADMIN_GROUPS = ['site-admins', 'org-admins'];

export enum QueryKey {
  AUTH = 'auth',
  CURRENT_USER = 'auth-current-user',
  DETAILS = 'auth-details',
}

export const ATLASSIAN_PARAMS = [
  'cp',
  'cv',
  'jwt',
  'lic',
  'xdm_c',
  'xdm_deprecated_addon_key_do_not_use',
  'xdm_e',
];
