import { fetcher } from '#common';
import type { KandkjSettingsResponse, IntuneSettingsResponse, KandjiRequest, JamfSettingsResponse, JamfRequest } from '../types';

export const integrationsApi = {
  getIntuneSettings: () => {
    return fetcher.get<IntuneSettingsResponse>('intune-settings');
  },
  disconnectIntune: (id: number | string) => {
    return fetcher.delete(`intune-settings/${id}`);
  },
  getKandjiSettings: () => {
    return fetcher.get<KandkjSettingsResponse>('kandji-settings');
  },
  createKandjiSettings: (settings: KandjiRequest) => {
    return fetcher.post<KandkjSettingsResponse>('kandji-settings', settings);
  },
  updateKandjiSettings: (settings: KandjiRequest, id: number | string) => {
    return fetcher.put<KandkjSettingsResponse>(`kandji-settings/${id}`, settings);
  },
  getJamfSettings: () => {
    return fetcher.get<JamfSettingsResponse>('jamf-settings');
  },
  createJamfSettings: (settings: JamfRequest) => {
    return fetcher.post<JamfSettingsResponse>('jamf-settings', settings);
  },
  updateJamfSettings: (settings: JamfRequest, id: number | string) => {
    return fetcher.put<JamfSettingsResponse>(`jamf-settings/${id}`, settings);
  },
};
